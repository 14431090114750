import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var isAuthenticated = !!sessionStorage.getItem('authToken');
    if (!isAuthenticated) {
        navigate('/login');
        return null;
    }
    return _jsx(_Fragment, { children: children });
};
export default ProtectedRoute;
